<template>
  <div class="container-fluid">
    <div class="row">
      <!-- Raise -->
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="box bgAll">
          <div class="box-title">
            Raised <small>{{ periodLabel }}</small>
          </div>
          <div class="box-figure" v-show="infoBox.raised > 0">{{ infoBox.raised | thousandSeparator }}</div>
          <div class="box-figure" v-show="infoBox.raised < 1">0</div>
          <div class="box-footer">
            <div><router-link :to="`/${authUserRole}/tickets/all`">view details </router-link></div>
            <div><b-icon-chevron-right></b-icon-chevron-right></div>
          </div>
        </div>
      </div>

      <!-- Open -->
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="box bgOpen">
          <div class="box-title">
            Open <small>{{ periodLabel }}</small>
          </div>
          <div class="box-figure" v-show="infoBox.open > 0">{{ infoBox.open | thousandSeparator }}</div>
          <div class="box-figure" v-show="infoBox.open < 1">0</div>
          <div class="box-footer">
            <div><router-link :to="`/${authUserRole}/tickets/open`">view details</router-link></div>
            <div><b-icon-chevron-right></b-icon-chevron-right></div>
          </div>
        </div>
      </div>

      <!-- InProgress -->
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="box bgInProgress">
          <div class="box-title">
            In Progress <small>{{ periodLabel }}</small>
          </div>
          <div class="box-figure" v-show="infoBox.open > 0">{{ infoBox.inProgress | thousandSeparator }}</div>
          <div class="box-figure" v-show="infoBox.inProgress < 1">0</div>
          <div class="box-footer">
            <div><router-link :to="`/${authUserRole}/tickets/in_progress`">view details</router-link></div>
            <div><b-icon-chevron-right></b-icon-chevron-right></div>
          </div>
        </div>
      </div>

      <!-- Escalated -->
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="box bgEscalated">
          <div class="box-title">
            Escalated <small>{{ periodLabel }}</small>
          </div>
          <div class="box-figure" v-show="infoBox.escalated > 0">{{ infoBox.escalated | thousandSeparator }}</div>
          <div class="box-figure" v-show="infoBox.escalated < 1">0</div>
          <div class="box-footer">
            <div><router-link :to="`/${authUserRole}/tickets/escalated`">view details</router-link></div>
            <div><b-icon-chevron-right></b-icon-chevron-right></div>
          </div>
        </div>
      </div>

      <!-- Closed -->
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="box bgClosed">
          <div class="box-title">
            Closed <small>{{ periodLabel }}</small>
          </div>
          <div class="box-figure" v-show="infoBox.closed > 0">{{ infoBox.closed | thousandSeparator }}</div>
          <div class="box-figure" v-show="infoBox.closed < 1">0</div>
          <div class="box-footer">
            <div><router-link :to="`/${authUserRole}/tickets/closed`">view details</router-link></div>
            <div><b-icon-chevron-right></b-icon-chevron-right></div>
          </div>
        </div>
      </div>

      <!-- Reopened -->
      <div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-6">
        <div class="box bgReopened">
          <div class="box-title">
            Reopened <small>{{ periodLabel }}</small>
          </div>
          <div class="box-figure" v-show="infoBox.reopened > 0">{{ infoBox.reopened | thousandSeparator }}</div>
          <div class="box-figure" v-show="infoBox.reopened < 1">0</div>
          <div class="box-footer">
            <div><router-link :to="`/${authUserRole}/tickets/reopened`">view details</router-link></div>
            <div><b-icon-chevron-right></b-icon-chevron-right></div>
          </div>
        </div>
      </div>

      <!-- end of info box -->
    </div>

    <!-- chart -->
    <div class="row">
      <div class="col-md-12">
        <div class="card mb-4">
          <div class="card-header">
            <div class="tools">
              <div>
                <div class="mr-2">{{ periodLabel }} reports</div>
                <select @change="selectPeriod($event)" class="form-control mr-2" style="width: 200px">
                  <option value>Reports period ...</option>
                  <option value="today">Today</option>
                  <option value="week">This week</option>
                  <option value="month">This month</option>
                  <option value="year">This year</option>
                </select>
              </div>

              <div>
                <span class="ml-5 mr-4">Date Range</span>
                <RangedatePicker @selected="selectedDateRange" i18n="EN" class="myDateRangePicker" righttoleft="true" />
              </div>

              <a :href="downloadLink" class="btn btn-success text-right" target="_blank">Download</a>
            </div>
            <!--  -->
          </div>
          <div class="card-body">
            <highcharts :options="chartOptions"></highcharts>
          </div>
        </div>
      </div>
    </div>

    <!-- table -->
    <!-- <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">{{ periodLabel }} category reports table</div>
          <div class="card-body-">
            <vue-good-table
              :columns="columns"
              :rows="categoriesStat"
              :line-numbers="true"
              :search-options="{
                enabled: true
              }"
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { Chart } from 'highcharts-vue'
import ISO8601Date from '../../helper/ISO8601Date'
/* import DateRangePicker from 'vue2-daterange-picker' */
import RangedatePicker from 'vue-rangedate-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'Dashboard',
  data() {
    return {
      infoBox: {},
      periodLabel: '',
      categoriesStat: [],
      startDate: '',
      endDate: '',
      reportDate: '',
      downloadLink: '',
      /* column */
      columns: [
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Raised',
          field: 'raised',
          type: 'number'
        },
        {
          label: 'Open',
          field: 'opened',
          type: 'number'
        },
        {
          label: 'In Progress',
          field: 'inProgress',
          type: 'number'
        },
        {
          label: 'Escalated',
          field: 'escalated',
          type: 'number'
        },
        {
          label: 'Closed',
          field: 'closed',
          type: 'number'
        },
        {
          label: 'Reopened',
          field: 'reopened',
          type: 'number'
        }
      ]
      /* ./ column */
    }
  },

  filters: {
    date(val) {
      return val ? val.toLocaleString() : ''
    }
  },

  components: {
    highcharts: Chart,
    RangedatePicker
  },

  mounted() {
    this.getReports('year')
  },

  methods: {
    selectPeriod(e) {
      if (e.target.value !== 'range') {
        this.getReports(e.target.value)
      }
    },

    getReports(period) {
      let loading = this.$loading.show()
      axios.get(`/office-screen/dashboard2?q=${period}&start=${this.startDate}&end=${this.endDate}`).then(res => {
        this.infoBox = res.data.infoBox
        this.periodLabel = res.data.periodLabel
        this.categoriesStat = res.data.categoriesStat
        this.downloadLink = res.data.downloadLink
        loading.hide()
      })
    },

    dateFormat(classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() > new Date()
      }
      return classes
    },

    /*   updateValues(v) {
      let startDate = `${v.startDate.getFullYear()}-${v.startDate.getMonth() + 1}-${v.startDate.getDate()}`
      let endDate = `${v.endDate.getFullYear()}-${v.endDate.getMonth() + 1}-${v.endDate.getDate()}`
      this.getReports('range', startDate, endDate)
    }, */

    selectedDateRange(v) {
      this.startDate = ISO8601Date(v.start)
      this.endDate = ISO8601Date(v.end)
      this.periodLabel = this.startDate + ' to ' + this.endDate
      this.getReports('range')
    }
  },

  computed: {
    client() {
      return this.$store.state.client
    },

    authUserRole() {
      return this.$store.state.authUser.Role
    },

    chartOptions() {
      let names = []
      let raised = []
      let open = []
      let inProgress = []
      let escalated = []
      let closed = []
      let reopened = []

      for (let index = 0; index < this.categoriesStat.length; index++) {
        names.push(this.categoriesStat[index].name)
        raised.push(this.categoriesStat[index].raised)
        open.push(this.categoriesStat[index].opened)
        inProgress.push(this.categoriesStat[index].inProgress)
        escalated.push(this.categoriesStat[index].escalated)
        closed.push(this.categoriesStat[index].closed)
        reopened.push(this.categoriesStat[index].reopened)
      }

      return {
        chart: {
          //type: "spline",
          type: 'column'
          //type: "area",
          //type: "line"
        },
        series: [
          /* {
            name: "Raised",
            data: raised,
            color: "#0061f2"
          }, */
          {
            name: 'Open',
            data: open,
            color: '#e81500'
          },
          {
            name: 'InProgress',
            data: inProgress,
            color: '#007bff'
          },
          {
            name: 'Escalated',
            data: escalated,
            color: '#f4a100'
          },
          {
            name: 'Closed',
            data: closed,
            color: '#00ac69'
          },
          {
            name: 'Reopened',
            data: reopened,
            color: '#17a2b8'
          }
        ],
        xAxis: {
          categories: names,
          crosshair: true
        },
        yAxis: {
          title: {
            text: 'Number of tickets'
          }
        },

        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        credits: {
          enabled: false
        }
      }
    }
  }
}
</script>

<style scoped>
.card-header.dateRager {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-shadow: none;
}

.tools {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tools div {
  display: flex;
  align-items: center;
}
</style>
